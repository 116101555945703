<template>
    <el-dialog :visible.sync="dialogVisible">
        <div class="box">
            <div class="main-box">
                <div class="myAvatar center-line">
                    <img v-if="form.userAvatar" :src="form.userAvatar" alt="">
                    <img v-else :src="`${VUE_APP_IMG_GET}user_icon.png`" alt="">

                    <el-upload ref="upload" name="file" :action="`${VUE_APP_BASE_API}api/minio/uploadFiles`"
                        :show-file-list="false" :data="{ fileFolder: 4 }" :before-upload="beforeAvatarUpload"
                        :on-success="headSuccess" :on-error="headError">
                        <!--  :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" :auto-upload="false" -->
                        <el-button class="center-justify upAvatar" slot="trigger" size="small"
                            type="primary">更换头像</el-button>
                    </el-upload>
                </div>

                <div style="width: 90%;">
                    <div class="vip-box">
                        <div class="title">
                            <img :src="`${VUE_APP_IMG_GET}free_vip.png`" alt="">
                            免费会员
                        </div>
                        <div>
                            剩余时长：{{ `${(form.memberTime || 0) + (form.packageTime || 0)}` }}秒
                        </div>
                        <!-- <div>
                            <span>会员时长 {{ form.memberTime || 0 }}秒</span>
                            <span v-if="form.packageTime">| 加油包时长 {{ form.packageTime }}秒</span>
                        </div> -->
                    </div>
                    <el-form ref="form" :model="form" label-position="top" :inline="false">
                        <el-form-item label="用户名" size="">
                            <el-input v-model="form.nickName" placeholder="请输入用户名" maxlength="20"
                                show-word-limit></el-input>
                        </el-form-item>
                        <div class="center-align">
                            <el-form-item label="性别">
                                <el-select v-model="form.gender" placeholder="请选择性别">
                                    <el-option label="男" value="男"></el-option>
                                    <el-option label="女" value="女"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="生日">
                                <el-date-picker type="date" placeholder="选择日期" v-model="form.birthday"
                                    style="width: 100%;"></el-date-picker>
                            </el-form-item>
                        </div>


                        <el-form-item label="简介">
                            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.userSynopsis"
                                maxlength="200" show-word-limit>
                            </el-input>
                        </el-form-item>

                    </el-form>

                    <div class="main-box-submit" @click="submit">
                        修改信息
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>

</template>

<script>

import { tokenLogin, updateUserInfo } from '@/request/api.js';



export default {
    name: "myInfo",
    data() {
        return {
            dialogVisible: false,
            fileList: [],
            form: {
                nickName: '',
                region: '',
                time: '',
                map: '',
                textarea: ''
            },
            VUE_APP_BASE_API: process.env.VUE_APP_BASE_API,
            VUE_APP_IMG_GET: process.env.VUE_APP_IMG_GET,
        }
    },
    // created() {
    //     this.login();
    // },
    methods: {
        headSuccess(res) {
            console.log("上传成功", res);
            let that = this;
            if (res.code === 0 && res.data[0]) {
                that.form.userAvatar = res.data[0];
                updateUserInfo(that.form).then((resA) => {
                    if (resA.code !== 0) {
                        that.$message.error('用户信息修改失败');
                    }
                }).catch(() => {
                    that.$message.error('用户信息修改错误');
                })
            }

        },
        headError() {
            let that = this;
            that.$message.error('图片上传错误');
        },
        beforeAvatarUpload() {
            // const isJPG = file.type === 'image/jpeg';
            // const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 2MB!');
            // }
            // return isJPG && isLt2M;
            return true
        },
        dialog() {
            this.dialogVisible = true;
            this.login();
        },
        handlePreview() {

        },
        handleRemove() {

        },
        login() {
            let that = this;
            tokenLogin({}).then((resA) => {
                console.log(1122, resA)
                that.form = resA.data
            })
        },
        submit() {
            let that = this;
            const loading = this.$loading({
                lock: true,
                text: '修改中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'custom-loading' // 添加自定义类名
            });
            updateUserInfo(that.form).then((resA) => {
                loading.close();

                if (resA.code === 0) {
                    console.log(1122, resA)
                    that.form = resA.data;
                    that.$notify({
                        title: '成功',
                        message: '修改完成',
                        type: 'success'
                    });
                    that.dialogVisible = false;
                }
            }).catch(() => {
                loading.close();
            })
        }

    }
}
</script>

<style scoped lang="less">
.vip-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(270deg, #CAD1DF 0%, #A2A8B4 53%);
    border-radius: 9px;
    padding: 10px 20px;
    color: #fff;

    .title {
        display: flex;
        align-items: center;

        img {
            width: 45px;
            height: 45px;
            margin-right: 10px;
        }
    }

}

/deep/ .el-dialog {
    width: 555px;
    height: 503px;
    background: #1B2128;
    border-radius: 18px;
}

/deep/ .el-input__inner {
    background: #000000;
    border: 1px solid #000000;
    color: #ffffff;
}

/deep/ .el-textarea__inner {
    background: #000000;
    border: 1px solid #000000;
    color: #ffffff;
}

/deep/ .el-form-item__label {
    padding: 0;
    color: #ffffff;
}

/deep/ .el-form-item {
    margin-bottom: 0;
    color: #ffffff;
}

/deep/ .el-input .el-input__count .el-input__count-inner {
    background: #000000;
    border: 1px solid #000000;
    color: #ffffff;
}

/deep/ .el-textarea .el-input__count {
    background: #000000;
    border: 1px solid #000000;
    color: #ffffff;
}

.el-dialog__wrapper {
    backdrop-filter: blur(8px);
}

.center-line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.center-justify {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.center-align {
    display: flex;
    justify-content: space-between;

}

.center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-box {
    width: 655px;
    // height: 603px;
    height: fit-content;
    background: #1B2128;
    border-radius: 18px 18px 18px 18px;
    border: 1px solid #26434F;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(10px);

    .myAvatar {
        img {
            width: 100px;
            height: 100px;
            margin: 10px 20px;
        }



    }
}

.upAvatar {
    width: 90px;
    height: 30px;
    background: rgba(31, 187, 237, 0.1);
    border-radius: 336px 336px 336px 336px;
    color: #ffffff;
}

.main-box-submit {
    width: 100%;
    height: 78px;
    border-radius: 315px 315px 315px 315px;
    font-weight: 500;
    font-size: 22px;
    background: #333A45;
    color: #656878;

    background: #1FBEF9;
    color: #fff;
    line-height: 78px;
    text-align: center;
    cursor: pointer;
    margin-top: 62px;
}
</style>