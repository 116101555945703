<template>
    <div class="bg">

        <el-card class="box-card">
            <div class="close" @click="$emit('close')">×</div>
            <el-tabs type="border-card">
                <el-tab-pane label="手机登录">
                    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0px"
                        class="demo-ruleForm">
                        <div class="from">
                            <div class="phone">+86</div>
                            <el-form-item prop="phone">
                                <el-input type="number" v-model="ruleForm.phone" placeholder="请输入手机号"></el-input>
                            </el-form-item>
                        </div>

                        <div class="from from2">
                            <el-form-item prop="age">
                                <el-input ref="fileitem" v-model.number="ruleForm.age" placeholder="请输入验证码"></el-input>
                            </el-form-item>
                            <el-button type="primary" :disabled="disabled" @click="submitForm(0)" class="yzm">
                                {{ btn_text }}
                            </el-button>
                        </div>
                    </el-form>
                    <div class="agreement">登录即代表同意 <span>《用户协议》</span> 和 <span>《隐私政策》</span></div>
                    <div class="btn"
                        :style="{ backgroundColor: iscode && isphone ? '#1ED8FE' : '#333A45', color: iscode && isphone ? 'white' : '#656878' }"
                        @click="submitForm(1)">提交</div>
                </el-tab-pane>
                <!-- <el-tab-pane label="扫码登录">
                    <div class="QRcode">
                    </div>
                    <div class="agreement">登录即代表同意 <span>《用户协议》</span> 和 <span>《隐私政策》</span></div>
                </el-tab-pane> -->
                <!-- <i class="el-icon-close"></i> -->
            </el-tabs>

        </el-card>
    </div>
</template>

<script>
import { sendCode, loginByPhone } from '@/request/api.js';
let is_active = true;

export default {
    name: "myLogin",
    data() {
        var checkAge = (rule, value, callback) => {
            if (!value) {
                this.iscode = false;
                return callback(new Error('请输入验证码'));
            }
            // setTimeout(() => {
            if (!Number.isInteger(value)) {
                this.iscode = false;
                callback(new Error('请输入数字值'));
            } else {
                if (value < 100000 || value > 999999) {
                    this.iscode = false;
                    callback(new Error('请输入6位验证码'));
                } else {
                    this.iscode = true;
                    callback();
                }
            }
            // }, 1000);
        };
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                this.isphone = false;
                callback(new Error('手机号不能为空'));
            } else {
                const reg = /^1[3-9]\d{9}$/; // 正则表达式验证手机号
                if (!reg.test(value)) {
                    this.isphone = false;

                    callback(new Error('手机号格式不正确'));
                } else {
                    this.isphone = true;
                    if (this.ruleForm.checkPass !== '') {
                        this.$refs.ruleForm.validateField('checkPass');
                    }
                    callback();
                }
            }
        };

        return {
            ruleForm: {
                phone: '',
                age: ''
            },
            rules: {
                phone: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                age: [
                    { validator: checkAge, trigger: ['blur', 'change'] }
                ]
            },
            disabled: false,
            times: 60,//剩余时间
            time: null, //定时器
            btn_text: '获取验证码',
            iscode: false,
            isphone: false

        };
    },
    created() {
        console.log(111111);

    },
    watch: {
        'ruleForm.age': {
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    this.$nextTick(() => {
                        this.$refs.fileitem.$emit('el.form.change');
                    });
                }
            }
        }
    },
    methods: {
        submitForm(e) {

            if (e) {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        if (!is_active) return;
                        is_active = false;
                        const loading = this.$loading({
                            lock: true,
                            text: '登录中',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)',
                            customClass: 'custom-loading' // 添加自定义类名
                        });


                        let data = { phonenumber: this.ruleForm.phone, verifyCode: this.ruleForm.age }
                        loginByPhone(data).then((res) => {
                            is_active = true;
                            console.log(res)
                            if (res.code == 0) {
                                this.$emit('loginFn')
                                loading.close();

                            } else {
                                this.$message.error(res.message);
                                loading.close();
                            }
                        }).catch((err) => {
                            is_active = true;
                            loading.close();
                            this.$message.error(err);
                        });

                    } else {
                        return false;
                    }
                });
            } else {
                const reg = /^1[3-9]\d{9}$/; // 正则表达式验证手机号
                if (reg.test(this.ruleForm.phone)) {
                    let data = { mobile: this.ruleForm.phone };
                    if (!is_active) return;
                        is_active = false;
                    sendCode(data).then((res) => {
                        is_active = true;
                        if (res.data === true && res.message === "ok") {
                            this.$message.success('发送手机验证码成功');
                            console.log(res)
                            this.time = setInterval(() => {
                                this.times -= 1
                                if (this.times == 0) {
                                    this.disabled = false
                                    this.btn_text = `获取验证码`
                                    clearInterval(this.time)
                                    this.time = null
                                    this.times = 60
                                } else {
                                    this.disabled = true
                                    this.btn_text = `${this.times}s后获取`
                                }
                            }, 1000)
                        } else {
                            this.$message.error('发送手机验证码失败');
                        }
                    }).catch(()=>{
                        is_active = true;
                    });

                } else {
                    this.$message.error('手机号格式不正确');
                }

            }


        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>

<style lang="less" scoped>
* {
    -webkit-touch-callout: none;
    /*系统默认菜单被禁用*/
    -webkit-user-select: none;
    /*webkit浏览器*/
    -khtml-user-select: none;
    /*早期浏览器*/
    -moz-user-select: none;
    /*火狐*/
    -ms-user-select: none;
    /*IE10*/
    user-select: none;
}

input {
    -webkit-user-select: auto;
    /*webkit浏览器*/
}

.bg {
    background-color: #475a61e0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    // width: 100%;
    // height: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .close {
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
    }

    .box-card {
        width: 555px;
        height: 503px;
        background-color: #1B2128;
        border-radius: 18px;
        border-style: none;
        padding: 0;
        // position: relative;
        text-align: center;

        margin: auto;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        .el-icon-close {
            color: white;
            position: absolute;
            top: 0;
            z-index: 999;
        }

        /deep/.el-card__body {
            padding: 42px 40px;



            .el-tabs.el-tabs.el-tabs--top.el-tabs--border-card {
                background-color: #1B2128;
                border: none;

                .el-tabs__header .is-top {
                    background-color: #1B2128;
                    border: none;
                }
            }

            .el-tabs__item {
                padding: 0;
                line-height: 0;
                height: 30px;
                width: 80px;
                padding-bottom: 5px;
                margin-right: 24px;
                font-size: 20px;
                border: 1 solid #1FB9F7 !important;
                position: relative;
                // &:after {
                //     content: '';
                //     width: 80px;
                //     height: 3px;
                //     background-color: #1FB9F7;
                //     display: block;
                //     position: absolute;
                //     bottom: 5px;

                // }

            }

            .is-active {
                color: #1ED8FE;
                border-bottom: 1 solid #1FB9F7;

                &:after {
                    content: '';
                    width: 80px;
                    height: 3px;
                    background-color: #1FB9F7;
                    display: block;
                    position: absolute;
                    bottom: 5px;

                }

            }

            .is-top {
                border-bottom: 3 solid #1FB9F7;
            }

            .el-tabs__content {
                padding: 0;
                margin-top: 15px;

                .el-form-item__error {
                    left: 15px;
                    top: 85%;
                }
            }
        }

        .from {
            width: 476px;
            height: 62px;
            background-color: #0D1116;
            border-radius: 14px;
            margin-bottom: 33px;
            // padding-top: 18px;
            display: flex;
            align-items: center;
            padding-left: 30px;
            color: white;

            .phone {
                height: 32px;
                line-height: 32px;
                padding-right: 20px;
                border-right: 1px solid white;
                font-size: 20px;

            }

            .yzm {
                margin-left: 30px;
            }

            /deep/.el-form-item {
                // 
                margin-top: 19px;

                .el-form-item__content {
                    line-height: 0px;

                    .el-input__inner {
                        font-size: 20px;
                        background-color: #0D1116;
                        border: 1px solid #0D1116;
                    }
                }
            }


        }
    }

    .agreement {
        font-size: 20px;
        margin-bottom: 60px;
        color: #BEC1C6;

        span {
            color: #22A4FF;
        }
    }

    .QRcode {
        width: 266px;
        height: 266px;
        border-radius: 9px;
        background: #D8D8D8;
        margin-bottom: 42px;
        margin-left: 104px;
        margin-top: 40px;
    }

    .btn {
        width: 476px;
        height: 78px;
        border-radius: 39px;
        background-color: #333A45;
        text-align: center;
        line-height: 78px;
        font-size: 22px;
        color: #656878;

        &:hover {
            background-color: #1ED8FE;
            color: white;
        }
    }
}
</style>

<style>
/* 如果以上代码不起作用就用  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>