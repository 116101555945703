<template>
    <div class="tab">
        <div ref="tab" :class="{ overflow: isEdit }">
            <span v-for="(item, index) in bg_category_list" :key="index" @click="selectBg(item, index)"
                :class="{ active: active_ind === index }">
                {{ item.categoryName }}
            </span>
        </div>
        <i v-if="isEdit" @click="isEdit = false" class="el-icon-arrow-down"></i>
        <i v-else @click="isEdit = true" class="el-icon-arrow-up"></i>

    </div>
</template>

<script>

import { getCategoryList } from '@/request/api.js';



export default {
    name: 'switchTab2',
    props: {
        // tabList: {
        //     type: Array,
        //     required: true
        // },
        // isEdit: {
        //     type: Boolean,
        //     default: true
        // },
        isAll: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            select: 0,
            isEdit: true,
            bg_category_list: [],
            active_ind: 0,
        }
    },
    created() {
        getCategoryList().then((res) => {
            console.log("获取背景分类",res);
            
            this.bg_category_list = [{categoryName:"全部",categoryId:undefined},...res.data];
        })
    },
    methods: {
        selectBg(item, ind) {
            this.active_ind = ind;
            this.$emit("bg", item)
        },
        // tabClick(e) {
        //     if (e.target.tagName === 'SPAN') {
        //         [...this.$refs.tab.children].forEach(element => {
        //             element.classList.remove('active')
        //         });
        //         e.target.classList.toggle('active');
        //     }

        //     this.$emit('tabClick', e.target.innerText)
        // },
    },
    // mounted() {
    //     // console.log(this.$refs.tab.children,'this.$refs.tab.children');

    //     this.$refs.tab.children[0].classList.add('active')
    // }

}
</script>

<style lang="less" scoped>
.tab {
    font-size: 16px;
    color: #999;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    width: 100%;
    cursor: pointer;

    >div {
        &.overflow {
            height: 28px;
            overflow: hidden;
        }
    }

    >i {
        padding: 5px;
    }

    span {
        margin-right: 22px;
        position: relative;
        margin-bottom: 10px;
        display: inline-block;
        // &:nth-child(1) {

        // }
    }

    .active {
        color: #fff;

        &::after {
            content: '';
            width: 17px;
            height: 2px;
            display: inline-block;
            background-color: red;
            position: absolute;
            bottom: -4px;
            left: 50%;
            transform: translateX(-50%);
            background: linear-gradient(135deg, #1FB9F7 0%, #1DDDFF 100%);

        }
    }
}
</style>